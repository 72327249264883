<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>广告管理</el-breadcrumb-item>
                    <el-breadcrumb-item>广告联盟</el-breadcrumb-item>
                    <el-breadcrumb-item>广告列表</el-breadcrumb-item>
                    <el-breadcrumb-item>{{titleHand}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form label-width="150px" ref="form" :model="form">
            <div class="admin_main_block admin_m15">
                <div class="admin_main_block_top">
                    <div class="admin_main_block_right">
                        <div>
                            <el-row>
                                <el-col :span="8">
                                    <el-button type="primary" @click="submitForm('form')">提交</el-button>
                                </el-col>
                                <el-col :span="8">
                                    <el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <div class="admin_form_main">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="计划设置" name="first">
                            <el-form-item label="投放人姓名" prop="userName"
                                          :rules="[{ required: true, message: '请填写投放人姓名', trigger: 'blur' },]">
                                <el-input :placeholder="form.userName" v-model="form.userName"></el-input>
                            </el-form-item>
                            <el-form-item label="门店ID" prop="shopId"
                                          :rules="[{ required: true, message: '请填写门店ID', trigger: 'blur' },]">
                                <el-input :placeholder="form.shopId" v-model="form.shopId"
                                          @blur="getShopInfo(form.shopId)"></el-input>
                            </el-form-item>
                            <el-form-item label="计划名称">
                                <el-input :placeholder="form.planName" v-model="form.planName"></el-input>
                            </el-form-item>
                            <el-form-item label="店铺名称">
                                <el-input :placeholder="form.shopName" v-model="form.shopName"
                                          :disabled="disabled"></el-input>
                            </el-form-item>
                            <el-form-item label="店铺区域">
                                <el-input placeholder="请填写店铺区域" v-model="form.shopArea"
                                          :disabled="disabled"></el-input>
                            </el-form-item>
                            <el-form-item label="店铺详细地址" prop="shopAddress"
                                          :rules="[{ required: true, message: '请填店铺详细地址', trigger: 'blur' },]">
                                <el-input placeholder="请填店铺详细地址" v-model="form.shopAddress"></el-input>
                            </el-form-item>
                            <el-form-item label="日限额" prop="dailyLimit"
                                          :rules="[{ required: true, message: '请填日限额', trigger: 'blur' },]">
                                <el-input type="number" placeholder="0" v-model="form.dailyLimit"></el-input>
                            </el-form-item>
                            <el-form-item label="总限额" prop="totalLimit"
                                          :rules="[{ required: true, message: '请填总限额', trigger: 'blur' },]">
                                <el-input type="number" placeholder="0" v-model="form.totalLimit"></el-input>
                            </el-form-item>
                        </el-tab-pane>
                        <el-tab-pane label="广告设置" name="second">
                            <el-form-item label="结算方式">
                                <el-select v-model="form.settleMethod" placeholder="请选择">
                                    <el-option label="现金" :value="1"></el-option>
                                    <el-option label="惠豆" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="手机号码" prop="phone"
                                          :rules="[{ required: true, message: '请填手机号码', trigger: 'blur' },]">
                                <el-input placeholder="请填手机号码" v-model="form.phone"></el-input>
                            </el-form-item>
                            <el-form-item label="广告副标题" prop="subtitled"
                                          :rules="[{ required: true, message: '请填广告副标题', trigger: 'blur' },]">
                                <el-input placeholder="请填广告副标题" v-model="form.subtitled"></el-input>
                            </el-form-item>
                            <el-form-item label="广告类型" prop="adType"
                                          :rules="[{ required: true, message: '请选择广告类型', trigger: 'blur' },]">
                                <el-select v-model="form.adType" placeholder="请选择">
                                    <el-option label="视频" :value="1"></el-option>
                                    <el-option label="图文" :value="2"></el-option>
                                    <el-option label="banner" :value="3"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="广告单价" prop="unitPrice"
                                          :rules="[{ required: true, message: '请填广告单价', trigger: 'blur' },]">
                                <el-input-number placeholder="请填广告单价" v-model="form.unitPrice" :precision="2"
                                                 :step="0.01" :min="0.01" :max="1000"></el-input-number>
                            </el-form-item>
                            <el-form-item label="广告位置" prop="adSpaceIds">
                                <el-checkbox-group v-model="adSpaceIds" @change="adSpaceCheck">
                                    <el-checkbox-button label="10" name="type">推荐商家</el-checkbox-button>
                                    <el-checkbox-button label="2" name="type">本地圈</el-checkbox-button>
                                    <el-checkbox-button label="3" name="type" :disabled="isUploadIndex === 3">视频区
                                    </el-checkbox-button>
                                    <el-checkbox-button label="4" name="type" :disabled="isUploadIndex === 4">图文区
                                    </el-checkbox-button>
                                </el-checkbox-group>
                            </el-form-item>
                            <el-form-item label="推广用户类型">

                            </el-form-item>
                            <el-form-item label="地域" prop="areaList">
                                <el-radio-group v-model="form.areaRange" @change="selectArea">
                                    <el-radio-button label="1">当前城市</el-radio-button>
                                    <el-radio-button label="2">不限</el-radio-button>
                                    <el-radio-button label="3">{{ arearange }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="性别" prop="gender"
                                          :rules="[{ required: true, message: '请选择性别', trigger: 'blur' },]">
                                <el-radio-group v-model="form.gender">
                                    <el-radio-button label="1">不限</el-radio-button>
                                    <el-radio-button label="2">男</el-radio-button>
                                    <el-radio-button label="3">女</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="手机系统" prop="phoneSystem"
                                          :rules="[{ required: true, message: '请选择手机系统', trigger: 'blur' },]">
                                <el-radio-group v-model="form.phoneSystem">
                                    <el-radio-button label="1">不限</el-radio-button>
                                    <el-radio-button label="2">安卓</el-radio-button>
                                    <el-radio-button label="3">IOS</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="用户年龄">
                                <el-radio-group v-model="agechecked" @change="sendAge">
                                    <el-radio-button label="不限">不限</el-radio-button>
                                    <el-radio-button label="18~25">18~25</el-radio-button>
                                    <el-radio-button label="25~30">25~30</el-radio-button>
                                    <el-radio-button label="30~40">30~40</el-radio-button>
                                    <el-radio-button label="指定年龄">{{ agerange }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="投放日期">
                                <el-radio-group v-model="form.dateType" @change="sendDate">
                                    <el-radio-button label="1">从今天开始长期投放</el-radio-button>
                                    <el-radio-button label="2">{{ daterange }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="投放时段">
                                <el-radio-group v-model="form.timeType" @change="sendTime">
                                    <el-radio-button label="1">全天</el-radio-button>
                                    <el-radio-button label="2">{{ timerange }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="出价方式">
                                <el-select v-model="form.bidMethod" placeholder="请选择">
                                    <el-option label="按有效广告计价" :value="1"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="邀请人ID">
                                <el-input placeholder="" v-model="form.bdId" :disabled="disabled"></el-input>
                            </el-form-item>
                        </el-tab-pane>
                        <el-tab-pane label="素材设置" name="third">
                            <el-form-item label="封面">
                                <upload-img
                                        @getUploadFile="getUploadImage"
                                        title=""
                                        field="cover"
                                        :param="upload.office"
                                        :urlPath="form.cover ? form.cover : ''"
                                ></upload-img>
                                <!--                                <el-image :src="form.cover" style="width: 100px"></el-image>-->
                                <div style="font-size: 12px;color: #606266;">
                                    上传的封面素材为图片：<br>
                                    分辨率：167*222、3:4的比例上传<br>
                                    图片格式支持：PNG、JPG、GIF格式
                                </div>
                            </el-form-item>
                            <div v-if="title === '视频'">
                                <el-form-item :label="title">
                                    <upload @get-upload-video="getUploadVideo" :contents="form.content"></upload>
                                </el-form-item>
                                <el-form-item label="有效时长">
                                    <el-input type="number" :placeholder="form.videoTime"
                                              v-model="form.videoTime"></el-input>
                                </el-form-item>
                            </div>
                            <el-form-item v-else :label="title">
                                <wangeditor
                                        @goods-content="goodsContent"
                                        :contents="form.content"
                                ></wangeditor>
                            </el-form-item>
                        </el-tab-pane>
                        <el-tab-pane label="链接设置" name="forth">
                            <el-form-item v-if="form.linkType === '2'" label="按钮图片">
                                <upload-img
                                        @getUploadFile="getUploadImage"
                                        title=""
                                        field="linkImg"
                                        :param="upload.office"
                                        :urlPath="form.linkImg ? form.linkImg : ''"
                                ></upload-img>
                                <div style="font-size: 12px;color: #606266;">
                                    1、图片大小不得大于10KB <br>
                                    2、图片格式为PNG、JPG <br>
                                    3、图片尺寸为46*46PX
                                </div>
                            </el-form-item>
                            <el-form-item v-if="form.linkType === '2'" label="按钮标题" prop="linkTitle"
                                          :rules="[{ required: true, message: '请填写按钮标题', trigger: 'blur' },{ min: 5, max: 14, message: '长度在 5 到 14 个字符', trigger: 'blur' }]">
                                <el-input placeholder="按钮标题不得小于5个字不得多于14个字" v-model="form.linkTitle"></el-input>
                            </el-form-item>
                            <el-form-item v-if="form.linkType === '2'" label="按钮文案" prop="linkText"
                                          :rules="[{ required: true, message: '请填写按钮文案', trigger: 'blur' },{ min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }]">
                                <el-input placeholder="按钮文案不得小于2个字不得多于5个字" v-model="form.linkText"></el-input>
                            </el-form-item>
                            <el-form-item label="目标详情-落地页" prop="linkType">
                                <el-radio-group v-model="form.linkType">
                                    <el-radio-button label="1">联盟商家</el-radio-button>
                                    <el-radio-button label="2">外部链接</el-radio-button>
                                    <el-radio-button label="3">内部链接</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item v-if="form.linkType === '2'" label="落地页Url" prop="targetPage"
                                          :rules="[{ required: true, message: '请填写落地页Url', trigger: 'blur' }]">
                                <el-input placeholder="仅支持http、https的链接请求" v-model="form.targetPage"></el-input>
                            </el-form-item>
                            <el-form-item v-if="form.linkType === '3'" label="路由" prop="targetPage"
                                          :rules="[{ required: true, message: '请填写路由Url', trigger: 'blur' }]">
                                <el-input placeholder="填写内部链接路由，不知道请咨询技术" v-model="form.targetPage"></el-input>
                            </el-form-item>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </el-form>
        <!--selectedData就是响应的数据.sync是2.3回归的语法糖-->
        <!--可以绑定iconDirection传入箭头的iconfont,Object-->
        <many-area-select v-if="isShowArea" :selectArea="selectData" @dialog="getDialog"></many-area-select>
        <age-range v-if="isShowAge" @dialog="getDialog"></age-range>
        <date-range v-if="isShowDateRange" @dialog="getDialog"></date-range>
        <time-range v-if="isShowTimeRange" @dialog="getDialog"></time-range>
    </div>
</template>

<script>
    import manyAreaSelect from "@/components/admin/adv/items/dialog/manyAreaSelect";
    import dateRange from "@/components/admin/adv/items/dialog/daterange";
    import timeRange from "@/components/admin/adv/items/dialog/timerange";
    import ageRange from "@/components/admin/adv/items/dialog/agerange";
    import upload from '@/components/admin/adv/items/vod/UploadAuth'
    import wangeditor from "@/components/seller/wangeditor";
    import uploadImg from "@/components/admin/upload";

    export default {
        components: {
            manyAreaSelect,
            dateRange,
            timeRange,
            ageRange,
            upload,
            uploadImg,
            wangeditor,
        },
        data() {
            return {
                titleHand: '添加',
                info: {"name": 1},
                obj: {},
                url: '',
                arr: [],
                activeName: "first",
                /*定义前一个活动Tab,初始化默认为 ActiveTab */
                oldTab: "add",
                /*定义Tab是否加载-第一个默认加载 */
                firstIs: true,
                secondIs: false,
                forthIs: false,
                fifthIs: false,
                sixthIs: false,
                thirdIs: false,
                disabled: true,
                isShowArea: false,
                isShowAge: false,
                isShowDateRange: false,
                isShowTimeRange: false,
                arearange: '选择地域',
                agerange: '指定年龄',
                daterange: '指定日期投放',
                timerange: '选择时段',
                adSpaceIds: ["10", "2"],
                agechecked: '不限',
                dateList: "1",
                timeList: "1",
                form: {
                    "planId": "",
                    "userId": "",
                    "userName": "",
                    "planName": "",
                    shopName:'',
                    "shopId": "",
                    "shopArea": '未知',
                    "shopProvinceCode": "",
                    "shopCityCode": "",
                    "shopAreaCode": "",
                    "shopAddress": "",
                    "dailyLimit": "",
                    "totalLimit": "",
                    "settleMethod": 2,
                    "phone": "",
                    "subtitled": "",
                    "adType": 1,
                    "unitPrice": 0.01,
                    "adSpaceIds": [],
                    "ruleId": "",
                    "areaRange": 1,
                    "areaList": [],
                    "gender": 1,
                    "phoneSystem": 1,
                    "ageRange": "不限",
                    "dateType": 1,
                    "dateList": [],
                    "timeType": 1,
                    "timeList": [],
                    "bidMethod": 1,
                    "bdId": '',
                    "content": '1',
                    "cover": '',
                    "selectArea": "",
                    "targetPage": '',
                    videoId:'',
                    linkType: '1',
                    linkTitle: '',
                    linkText: '',
                    linkImg: '',
                },
                ageArr: [{
                    name: '不限',
                    bg: false,
                    isChange: 0,
                }, {
                    name: '18~25',
                    bg: false,
                    isChange: '',
                }, {
                    name: '25~30',
                    bg: false,

                    isChange: '',
                }, {
                    name: '30~40',
                    bg: false,
                    isChange: '',
                }, {
                    name: '指定年龄',
                    bg: true,
                    isChange: '',
                }],
                shopArr: [],
                isUploadIndex: 0,//广告位置
                title: "视频",
                content: '',
                upload: {
                    office: {fileType: "office"},
                },
                shopId: '',
                selectData: [],
            };
        },
        methods: {
            handleClick(tab) {
                this[tab.name + "Is"] = true;
                if (tab.name != this.oldTab) {
                    this[this.oldTab + "Is"] = false;
                    this.oldTab = tab.name;
                }
            },
            submitForm(formName) {
                console.log(this.form);
                this.form.status = 2;
                if (this.agechecked === '') {
                    this.$message({type: "warning", message: '请选择用户年龄'});
                    return false;
                }

                var index3 = this.adSpaceIds.includes('3');
                var index4 = this.adSpaceIds.includes('4');
                if (!index3 && !index4) {
                    this.$message({type: "warning", message: '请选择视频、图文广告位'});
                    return false;
                }
                if (this.form.areaRange === 3 && (this.form.areaList === '' || this.form.areaList.length === 0)) {
                    this.$message({type: "warning", message: '请上选择指定区域'});
                    return false;
                }
                if (this.form.dateType === 2 && (this.form.dateList === '' || this.form.dateList.length === 0)) {
                    this.$message({type: "warning", message: '请上选择指定日期'});
                    return false;
                }
                if (this.form.timeType === 2 && !this.form.timeType) {
                    this.$message({type: "warning", message: '请上选择指定时间'});
                    return false;
                }
                this.form.adSpaceIds = this.adSpaceIds.toString();
                if (this.form.cover === '' || !this.form.cover) {
                    // this.$message({type: "warning", message: '请上传封面'});
                    // return false;
                }
                if (!this.form.content) {
                    this.$message({type: "warning", message: '上传素材'});
                    return false;
                }
                if (index3 && this.form.videoTime < 1) {
                    this.$message({type: "warning", message: '请填写有效时长'});
                    return false;
                }
                //外链必填
                if (this.form.linkType === '2'){
                    if (this.form.linkImg === '') {
                        this.$message({type: "warning", message: '请上传链接按钮图片'});
                        return false;
                    }
                    if (this.form.targetPage === '') {
                        this.$message({type: "warning", message: '输入目标详情页链接'});
                        return false;
                    }
                }

                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let url = this.$api.advertisementCreatePlan;
                        console.log("submitForm", this.form)
                        if(this.form.planId){
                            url = this.$api.advertisementReviewPlan;
                        }
                        this.$post(
                            url,
                            this.form
                        ).then((res) => {
                            if (res.code === 1) {
                                this.$message({
                                    type: "success",
                                    message: "成功",
                                });
                                this.$router.push({name: 'advItemsList'});
                            } else {
                                this.$message({
                                    type: "info",
                                    message: res.msg,
                                });
                            }
                        });
                    } else {
                        this.$message({
                            type: "warning",
                            message: "请填写完整信息！",
                        });
                        return false;
                    }
                });
            },
            infoData() {
                let id = this.$route.query.id;
                let url = '';
                if (id) {
                    this.titleHand = '编辑';
                    url = this.$api.advertisementPlanDetail + id;
                } else {
                    return false;
                }

                this.$get(
                    url,
                    {}
                ).then((res) => {
                    this.form = res.data;
                    this.form.shopName = '';
                    this.shopId = this.form.shopId;
                    this.agechecked = '指定年龄';
                    this.ageArr.forEach((item) => {
                        if (item.name === this.form.ageRange) {
                            this.agechecked = item.name;
                        }
                    })
                    if (this.form.areaList && this.form.areaList !== null) {
                        this.arearange = "已选择（" + this.form.areaList.length + ")";
                    }
                    if (this.form.dateList && this.form.dateList.length > 0) {
                        this.dateList = '2';
                        this.daterange = this.form.dateList[0].startDate + "~" + this.form.dateList[0].endDate;
                    }
                    if (this.form.timeList && this.form.timeList.length > 0) {
                        this.timeList = '2';
                        this.timerange = this.form.timeList[0].startTime + "~" + this.form.timeList[0].endTime;
                    }
                    if (this.form.selectArea) {
                        this.selectData = JSON.parse(this.form.selectArea);
                    }
                    this.adSpaceIds = this.form.adSpaceIds.split(',');
                    if(this.form.adType === 1){
                        this.title = "视频";
                    }
                    if(this.form.adType > 1){
                        this.title = "图文";
                    }
                });
                setTimeout(()=>{
                    this.getShopInfo(this.form.shopId)
                },1000)
            },
            getShopInfo(shopid) {
                if (!shopid) {
                    return false;
                }
                this.$get(
                    this.$api.systemShopDetail + '/' + shopid,
                    {}
                ).then((res) => {
                    if (res.code === 1) {
                        let item = res.data;
                        // this.form.shopArea = item.shopArea;
                        this.form.shopAreaCode = item.shopAreaCode;
                        this.form.shopAddress = item.shopAddress
                        this.form.shopName = item.shopName;
                        console.log(1111,item.shopName,this.form.shopName)
                    }
                });
            },
            getAllAreaList(data) {
                const val = data["code"];
                const name = data["name"];
                if (val && val.length > 0 && Array.isArray(val)) {
                    this.form.officeArea = name.join("/");
                }
            },
            selectArea(e) {
                if (e == 3) {
                    this.isShowArea = true;
                } else {
                    this.isShowArea = false;
                    this.arearange = '选择地域';
                }
            },
            sendAge(e) {
                console.log(e);
                this.isShowAge = false;
                if (e == 5) {
                    this.isShowAge = true;
                } else {
                    this.agerange = '指定年龄';
                }
            },
            sendDate(e) {
                console.log(e);
                this.isShowDateRange = false;
                if (e == 2) {
                    this.isShowDateRange = true;
                } else {
                    this.daterange = '指定日期投放';
                }
            },
            sendTime(e) {
                console.log(e);
                this.isShowTimeRange = false;
                if (e == 2) {
                    this.isShowTimeRange = true;
                } else {
                    this.timerange = '选择时段';
                }
            },
            getDialog(val) {
                this.isShowArea = val;
                this.isShowAge = val;
                this.isShowDateRange = val;
                this.isShowTimeRange = val;
            },
            adSpaceCheck(e) {
                if (e) {
                    var index3 = e.includes('3');
                    var index4 = e.includes('4');
                    if (index3) {
                        this.isUploadIndex = 4;
                        this.title = "视频";
                        // this.form.content = "";
                    }
                    if (index4) {
                        this.isUploadIndex = 3;
                        this.title = "图文";
                        // this.form.content = "";
                    }
                    if (!index3 && !index4) {
                        this.isUploadIndex = 0;
                        // this.form.content = "";
                    }
                    console.log(e)
                }
            },
            // 富文本编辑内容变化
            goodsContent(res) {
                this.form.content = res.replace(/(o:p)/gi, 'p');
            },
            getUploadImage(val) {
                this.form[val.field] = val.url;
            },
            getUploadVideo(val) {
                this.$nextTick(() => {
                    this.form.cover = '';//重置封面
                    this.form.content = val.url;
                    this.form.videoTime = Math.floor(val.time);
                    this.form.videoId = val.videoId;
                    this.$forceUpdate();
                });
            },
        },
        created() {
            this.$nextTick(() => {
                this.infoData();
            });
        },

    };
</script>
<style lang="scss" scoped>
    .el-breadcrumb {
        line-height: 32px;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 146px;
        height: 146px;
        line-height: 146px;
        text-align: center;
    }

    .avatar-upload {
        width: 146px;
        height: 146px;
    }

    .is_master {
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        width: 164px;
        text-align: center;
        display: none;
    }

    .goods_class_add_left {
        float: left;
        margin-right: 15px;
        // border:1px solid #e1e1e1;
        padding: 0 20px;
        border-radius: 5px;
        background: #f1f1f1;
    }

    .el-icon-delete {
        cursor: pointer;
    }

    .el-icon-delete:hover {
        color: red;
    }

    .admin_form_main .el-form-item {
        width: 600px;
    }

    .admin_main_block_right {
        width: 280px;
    }
</style>
